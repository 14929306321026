import React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { formFullExpirationStatus } from 'src/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { TimerLabel } from '../timer_label';
import { buildVariant } from '../../utils/mui';

const useStyles = makeStyles({
    timeStatusWrapper: {
        cursor: 'pointer',
        textAlign: 'center',
        ...buildVariant('normal', 12, 'textPrimary')
    }
});

export const InterviewStatus = ({ timerValue, interview, className }) => {
    const { timeStatusWrapper } = useStyles({});
    const { t, i18n } = useTranslation();
    const {
        expirationTimeStatus, timerEnabled,
        timerCount, expirationDate, screeningStatus
    } = interview;

    const isES = i18n.language === 'es';
    const tooltipLabel = moment(expirationDate).format(isES ? '[el] D [de] MMMM [de] YYYY' : 'MMMM Do YYYY, h:mm a');


    return (
        <div className={`${timeStatusWrapper} ${className}`}>
            {
                (timerEnabled && !screeningStatus)
                    ? (
                        <TimerLabel
                            value={timerValue}
                            totalValue={timerCount * 60}
                        />
                    ) : (
                        <>
                            {
                                expirationTimeStatus && (
                                    <Tooltip
                                        title={t('Assessment expires on {{tooltipLabel}}', { tooltipLabel })}
                                        placement="bottom-end"
                                    >
                                        <div>{formFullExpirationStatus(expirationTimeStatus, i18n.language)} {t('remaining')}</div>
                                    </Tooltip>
                                )
                            }
                        </>
                    )
            }
        </div>
    );
};
