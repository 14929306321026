import React, { Fragment, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation, Trans } from 'react-i18next';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { InterviewPageStoreCtx } from 'src/pages/interview/store';
import { CustomDialog } from 'src/components/custom_dialog';

import { TextField } from '../text_field';
import { interviewApi } from '../../api/interview';
import Select from '../select';

import { useStyles } from './styles';


const survey = [
    {
        name: 'gender',
        fieldName: 'Gender',
        options: ['Select an option', 'Male', 'Female', 'Other (please specify)', 'Prefer not to answer']
    }, {
        name: 'race',
        fieldName: 'Race/Ethnicity',
        options: ['Select an option', 'Hispanic or Latino', 'White', 'Black or African American',
            'Native Hawaiian or Other Pacific Islander', 'Asian', 'American Indian or Alaskan Native',
            'Two or More Races', 'Other (please specify)', 'Prefer not to answer']
    }, {
        name: 'veteranStatus',
        fieldName: 'Veteran status (US only)',
        options: ['Select an option', 'Disabled Veteran', 'Recently Separated Veteran',
            'Active Wartime or Campaign Badge Veteran', 'Armed Forces Service Medal Veteran',
            'I am a protected veteran, but I choose not to self-identify the classifications to which I belong',
            'I am NOT a protected veteran']
    }
];


export const EEOCDialog = observer(({ open, setCsatDialogOpened, setEeocDialogOpened }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { redirectAfterSubmit, interview } = useContext(InterviewPageStoreCtx);
    const [submitProcess, setSubmitProcess] = useState(false);
    const [loadingSkip, setLoadingSkip] = useState(false);
    const [choice, setChoice] = useState({
        gender: {
            option: 'Select an option',
            other: ''
        },
        race: {
            option: 'Select an option',
            other: ''
        },
        veteranStatus: {
            option: 'Select an option'
        }
    });

    const returnResponse = (field) => {
        if (choice[field].option.startsWith('Se')) return '';
        if (choice[field].option.startsWith('Ot')) return `Other (please specify): ${choice[field].other}`;
        return choice[field].option;
    };

    const handleSubmit = async () => {
        const { id, candidateFeedbackSeen } = interview;
        setSubmitProcess(true);
        try {
            await interviewApi.setEEOCSurvey(
                id,
                returnResponse('gender'),
                returnResponse('race'),
                returnResponse('veteranStatus')
            );
            if (!candidateFeedbackSeen) {
                setEeocDialogOpened(false);
                setCsatDialogOpened(true);
            } else {
                redirectAfterSubmit();
            }
        } catch {
            setSubmitProcess(false);
        }
    };

    const handleSkip = async () => {
        const { id, candidateFeedbackSeen } = interview;
        if (!submitProcess && !loadingSkip) {
            setLoadingSkip(true);
            try {
                await interviewApi.setEEOCSurveySkipped(id);
            } catch {
                setLoadingSkip(false);
            }
            if (!candidateFeedbackSeen) {
                setLoadingSkip(false);
                setEeocDialogOpened(false);
                setCsatDialogOpened(true);
                return;
            }
            redirectAfterSubmit();
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setChoice({ ...choice, [name]: { ...choice[name], option: value } });
    };

    const handleChangeOther = (e, name) => {
        const { value } = e.target;
        setChoice({ ...choice, [name]: { ...choice[name], other: value } });
    };

    const actionButtons = [
        <Button
            color="primary"
            className={classes.buttonStyle}
            onClick={handleSkip}
            key={1}
            classes={{ root: classes.flatButton }}
            disabled={submitProcess || loadingSkip}
        >
            <Trans>Skip</Trans>
            {loadingSkip && <CircularProgress size={18} className={classes.circularProgressSkip} />}
        </Button>,
        <Button
            onClick={handleSubmit}
            key={0}
            variant="contained"
            color="primary"
            disabled={submitProcess || loadingSkip || Object.values(choice).every(({ option }) => option.startsWith('Se'))}
            className={classes.buttonStyle}
        >
            {
                submitProcess ? (
                    <>
                        <span className={classes.submitting}>{t('Submitting...')}</span>
                        <CircularProgress size={18} className={classes.circularProgress} />
                    </>
                ) : (
                    <Trans>Submit</Trans>
                )
            }
        </Button>
    ];

    return (
        <CustomDialog
            open={open}
            handleClose={handleSkip}
            classes={{ paper: classes.dialogPaper }}
            title={<div className={classes.titleStyle}><Trans i18nKey="eeocTitle"/></div>}
            content={(
                <>
                    <div className={classes.description}><Trans i18nKey="eeocDesc"/></div>
                    <>
                        {survey.map(({ fieldName, name, options }, index) => (
                            <Fragment key={index}>
                                <Select
                                    className={classes.select}
                                    value={choice[name].option}
                                    handleChange={handleChange}
                                    options={options.map((item) => ({
                                        label: item,
                                        value: item
                                    }))}
                                    name={name}
                                    label={fieldName}
                                    key={fieldName}
                                />
                                {choice[name].option.startsWith('Ot') && (
                                    <TextField
                                        label={t('Please specify')}
                                        value={choice[name].other}
                                        fullWidth
                                        maxLength={500}
                                        multiline
                                        hasCounter
                                        disabled={submitProcess || loadingSkip}
                                        className={classes.input}
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot
                                            }
                                        }}
                                        onChange={e => handleChangeOther(e, name)}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </>
                </>
            )}
            actions={actionButtons}
        />
    );
});
